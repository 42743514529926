import { fetchAuthSession } from 'aws-amplify/auth';
import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { getTenantBackendUrl } from '@/utility/tenant-config';

const baseUrl = getTenantBackendUrl();

async function parseResponse<T>(response: AxiosResponse<T>) {
  const { data } = response;

  if (!response.status.toString().startsWith('2')) {
    if (typeof data === 'string') {
      throw new Error(data);
    }

    if (
      typeof data === 'object'
      && data
      && 'message' in data
      && typeof data.message === 'string'
    ) {
      throw new Error(data.message);
    }

    throw new Error('Request failed');
  }

  return data;
}

async function getAuthHeader(): Promise<Record<'Authorization', string>> {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {};
  if (!accessToken) {
    throw new Error('Missing access token');
  }
  const headers = { Authorization: `Bearer ${accessToken}` };
  return headers;
}

export const client = {
  async getTenant<T>(path: string) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      baseURL: baseUrl,
    };
    const response = await axios<T>(path, config);

    return parseResponse(response);
  },

  async get<T>(path: string) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      headers: await getAuthHeader(),
      baseURL: baseUrl,
    };
    const response = await axios<T>(`${path}`, config);

    return parseResponse(response);
  },

  async post<T>(path: string, body: unknown) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      data: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        ...(await getAuthHeader()),
      },
    };

    const response = await axios<T>(`${baseUrl}${path}`, config);

    return parseResponse(response);
  },

  async put<T>(path: string, body: unknown) {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      data: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        ...(await getAuthHeader()),
      },
    };

    const response = await axios<T>(`${baseUrl}${path}`, config);

    return parseResponse(response);
  },

  async patch<T = boolean>(path: string, body: unknown) {
    const config: AxiosRequestConfig = {
      method: 'PATCH',
      data: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        ...(await getAuthHeader()),
      },
    };

    const response = await axios<T>(`${baseUrl}${path}`, config);

    return parseResponse(response);
  },

  async delete<T = boolean>(path: string, body?: unknown) {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      headers: {
        ...await getAuthHeader(),
      },
    };

    if (body) {
      config.headers!['Content-Type'] = 'application/json';
      config.data = JSON.stringify(body);
    }

    const response = await axios<T>(`${baseUrl}${path}`, config);

    return parseResponse(response);
  },
};
