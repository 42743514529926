<script setup lang="ts">
import type Popover from 'primevue/popover';
import { ref } from 'vue';

const popover = ref<InstanceType<typeof Popover>>();

function close() {
  popover.value?.hide();
}
</script>

<template>
  <div>
    <i-button v-bind="$attrs" @click="popover?.toggle" />
    <p-popover ref="popover">
      <slot :close />
    </p-popover>
  </div>
</template>
