export function getTenantBackendUrl(): string {
  const backendUrls: Record<string, string> = {
    demo: 'https://i2qf1a7521.execute-api.eu-central-1.amazonaws.com',
    develop: 'https://egjmfm40qk.execute-api.eu-central-1.amazonaws.com',
    test: '', // TODO: Inject this from CDK based on SSM Parameter during deployment
  };

  const { host } = window.location; // demo.cockpit.intellivend.app
  if (host.includes('localhost')) {
    return import.meta.env.VITE_BACKEND_ENDPOINT;
  }
  const tenantName = host.split('.cockpit.')[0];
  return backendUrls[tenantName];
}
