import { Amplify } from 'aws-amplify';
import { client } from '@/services/client';

function configureAmplify(userPoolId: string, userPoolClientId: string) {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId,
        userPoolClientId,
      },
    },
  });
}

// TODO this is a workaround for the user pool config not being available when the app is loaded, see below
configureAmplify(import.meta.env.VITE_USERPOOL_ID, import.meta.env.VITE_USERPOOL_CLIENT_ID);

interface UserPoolConfig {
  userPoolId: string;
  clientId: string;
}

async function getUserPoolConfig(): Promise<UserPoolConfig> {
  return client.getTenant('/api/tenant-config');
}

// FIXME Warning! This seems to be not working. A logged in user is not recognized after reload.
getUserPoolConfig().then(response => {
  configureAmplify(response.userPoolId, response.clientId);
}).catch(error => {
  console.error('Failed to get tenant config', error);
});
